/** @constant {string} */
export const WINDOWS = "windows";
/** @constant {string} */
export const MACOS = "macos";
/** @constant {string} */
export const IOS = "ios";
/** @constant {string} */
export const ANDROID = "android";
/** @constant {string} */
export const LINUX = "linux";

/**
 * Detects if the current client browser is IE11 or earlier.
 *
 * @function
 * @returns {boolean} true if current browser is Microsoft IE.
 */
export const browserIsIE = () => {
    const userAgent = window.navigator.userAgent;
    return userAgent.includes("MSIE") || userAgent.includes("Trident");
};

/**
 * Detects the current client OS.
 *
 * @function
 * @returns {MACOS|IOS|WINDOWS|ANDROID|LINUX|null} the current OS client.
 */
export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;

    // Mac platforms: 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'
    if(platform.includes("Mac")) {
        return MACOS;
    }

    // iOS platforms: 'iPhone', 'iPad', 'iPod', 'Pike'
    if(platform.includes("iP") || platform.includes("Pike")) {
        return IOS;
    }

    // Windows platforms: 'Win32', 'Win64', 'Windows', 'WinCE'
    if(platform.includes("Win")) {
        return WINDOWS;
    }

    // Android is in user agent string
    if(userAgent.includes("Android")) {
        return ANDROID;
    }

    // Linux is in user agent string
    if(userAgent.includes("Linux")) {
        return LINUX;
    }

    // Return null if unknown
    return null;
};