import {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from "react";
import {isEqual} from "lodash";

/**
 * Returns callback to post custom events using provided event key.
 * @param eventKey
 */
export const usePostCustomEvent = <EventData extends object>(eventKey: string): ((eventData?: EventData) => void) => useCallback((eventData?: EventData) => {
    // Post event
    document.dispatchEvent(new CustomEvent(eventKey, {
        detail: eventData,
        bubbles: true,
        cancelable: true,
        composed: true
    }));
}, [eventKey]);

export type PostEvent<EventData extends object> = (eventData?: EventData) => void;
/**
 * Listens to the provided event key, and returns a callback to post custom events using provided event key.
 * Re-renders whenever a new event is received.
 */
export const useCustomEvents = <EventData extends object>(eventKey: string, eventHandler: (event?: EventData, postEvent?: PostEvent<EventData>) => void): PostEvent<EventData> => {
    const postEvent = usePostCustomEvent<EventData>(eventKey);

    useEffect(() => {
        // Create event listener
        const handleEvent = (event: Event & { detail?: EventData }) => {
            eventHandler(event?.detail, postEvent);
        };
        document.addEventListener(eventKey, handleEvent);

        return () => {
            document.removeEventListener(eventKey, handleEvent);
        };
    }, [eventKey, eventHandler]);

    return postEvent;
};

export const SHARED_DATA_UPDATE_EVENT = "SHARED_DATA_UPDATE_EVENT";

/**
 * similar to useState(), but uses a shared local storage to persist the data between sessions.
 * Custom Events are used to sync changes between multiple useSharedData hooks that are referencing the
 * same dataKey.
 */
export const useSharedData = <SharedData extends any>(dataKey: string, defaultValue?: SharedData):
    [SharedData | undefined, Dispatch<SetStateAction<SharedData | undefined>>] => {
    const {localStorage} = window;
    const getStoredValue = (): SharedData | undefined => {
        const savedValue = localStorage.getItem(dataKey);
        try {
            return savedValue ? JSON.parse(savedValue) : undefined;
        } catch {
            return undefined;
        }
    };
    const initialSavedValue = useMemo(getStoredValue, []);
    const [value, setLiveValue] = useState<SharedData | undefined>(initialSavedValue === undefined ? defaultValue : initialSavedValue);
    const eventHandler = useCallback((event?: { dataKey: string }) => {
        if (event?.dataKey === dataKey) {
            setLiveValue(getStoredValue());
        }
    }, [dataKey]);
    const postEvent = useCustomEvents<{ dataKey: string }>(SHARED_DATA_UPDATE_EVENT, eventHandler);

    const setValue: Dispatch<SetStateAction<SharedData | undefined>> = (valueOrSetter) => {
        let newValue = valueOrSetter;
        if (typeof valueOrSetter === "function") {
            // @ts-ignore Not sure why it thinks this is not callable, the line above literally checks it is a function...
            newValue = valueOrSetter(getStoredValue());
        }
        if (!isEqual(newValue, value)) {
            localStorage.setItem(dataKey, JSON.stringify(newValue));
            // TODO: Setting the value here might cause 2 updates per each call, as it is listening to its own event... need to check this isn't an issue
            setLiveValue(newValue);
            postEvent({dataKey});
        }
    };

    return [value, setValue];
};