const hasValue = (value: any) => value !== undefined && value !== null;

/**
 * Helper function to extract the data content from a resource's returned data. Checks
 * data, data.content, data.data, data.data.content, and returns the value found at "deepest" of these positions (if any)
 *
 * @function
 * @param {object} data the data to fetch the content from.
 * @param {*} defaultData default value to return if no value is found.
 * @returns {*} the found value, or the default value.
 */
export const getDataContent = (data?: {[key: string]: any} | any, defaultData?: Object) => {
    if (hasValue(data)) {
        if (hasValue(data.data)) {
            return hasValue(data.data.content) ? data.data.content : data.data;
        }
        return hasValue(data.content) ? data.content : data;
    }
    return defaultData;
};

/**
 * Helper function to extract the data content from a resource's returned data. Checks
 * data, data.content, data.data, data.data.content, and returns the value found at the "deepest" of these positions (if any)
 * If no value is found, or a non-array value is found, an empty array is returned instead
 *
 * @function
 * @param {object} data the data to fetch the content from.
 * @returns {*} the found array value, or an empty array.
 */
export const getArrayDataContent = (data?: {[key: string]: any} | any) => {
    const arrayData = getDataContent(data, []);
    return Array.isArray(arrayData) ? arrayData : [];
};